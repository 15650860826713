import { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Layout from '../components/layout';
import useWaitlist from '../hooks/useWaitlist';
import { PositionData } from '../types/waitlist';
import { DEFAULT_ACCOUNT_ID } from '../config-global';

const Success: React.FC = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const { referralId } = location.state || {};
  const waitlistEntries = useWaitlist({
    id: DEFAULT_ACCOUNT_ID,
    applicationId: id || '',
  });

  const calculatePosition = (): PositionData => {
    const position = waitlistEntries.length;
    const peopleAhead = Math.max(0, position - 1);
    return {
      position,
      isFirst: peopleAhead === 0,
      peopleAhead,
    };
  };

  const getPeopleText = (count: number): string => {
    if (count === 1) {
      return 'person is';
    }
    return 'people are';
  };

  const getPositionText = (): string => {
    if (waitlistEntries.length === 0) {
      return 'Loading...';
    }

    const { isFirst, peopleAhead } = calculatePosition();

    if (isFirst) {
      return "You're first in line!";
    }

    const peopleText = getPeopleText(peopleAhead);
    return `${peopleAhead} ${peopleText} ahead of you`;
  };

  const getReferralUrl = () => {
    const url = new URL(window.location.href);
    const waitlistId = url.pathname.split('/success/')[1];
    return `${url.protocol}//${url.host}/waitlist/${waitlistId}?ref=${referralId}`;
  };

  const shareUrl = async () => {
    try {
      await navigator.share({
        text: getReferralUrl(),
      });
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 2000);
    } catch (err) {
      console.error('Error sharing:', err);
    }
  };

  return (
    <Layout>
      <h1 className="text-2xl font-bold text-center mb-6">Success</h1>
      <p className="text-gray-600 mb-4">{getPositionText()}</p>
      <p className="text-gray-600 mb-4">Your referral link is:</p>
      <div className="bg-gray-50 p-3 rounded-lg break-all mb-4 font-mono text-sm">
        {getReferralUrl()}
      </div>
      <div className="relative">
        <button
          onClick={shareUrl}
          className="w-full bg-gradient-to-r from-purple-500 to-pink-500 text-white py-2 px-4 rounded-lg hover:opacity-90 transition-opacity flex items-center justify-center gap-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
            />
          </svg>
          Share Link
        </button>

        {/* Tooltip */}
        {showTooltip && (
          <div className="absolute -top-10 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white px-3 py-1 rounded text-sm whitespace-nowrap">
            Copied to clipboard!
            {/* Arrow */}
            <div className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 w-2 h-2 bg-gray-800 rotate-45"></div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Success;
