import { doc, getDoc } from 'firebase/firestore';
import { DB } from '../config';
import { Application } from '../../types/waitlist';

export const application = async (accountId: string, appId: string) => {
  try {
    const applicationRef = doc(
      DB,
      'accounts',
      accountId,
      'applications',
      appId
    );
    const querySnapshot = await getDoc(applicationRef);

    if (querySnapshot.exists()) return querySnapshot.data() as Application;

    return null;
  } catch (error) {
    console.error('Error fetching user data:', error);
    return {
      applications: [],
      firstApplicationId: null,
      passcode: '',
    };
  }
};
