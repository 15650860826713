import { useState, useEffect } from 'react';
import { db } from '../firebase-lib/firestore';
import { WaitlistEntry } from '../types/waitlist';
import { UseWaitlistProps } from '../types/waitlist';

const useWaitlist = ({ id, applicationId }: UseWaitlistProps) => {
  const [waitlistEntries, setWaitlistEntries] = useState<WaitlistEntry[]>([]);

  useEffect(() => {
    const fetchWaitlistEntries = async () => {
      const entries = await db.getWaitlistEntries(id as string, applicationId);
      setWaitlistEntries(entries as WaitlistEntry[]);
    };
    fetchWaitlistEntries();
  }, []);

  return waitlistEntries;
};

export default useWaitlist;
