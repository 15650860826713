import React, { useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import UAParser from 'ua-parser-js';
import { v4 as uuidv4 } from 'uuid';
import { db } from '../firebase-lib/firestore';
import { FormData } from '../types/waitlist';
import Layout from '../components/layout';
import { DEFAULT_ACCOUNT_ID } from '../config-global';

const UserData: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (formData: FormData) => {
    setIsSubmitting(true);
    try {
      const parser = new UAParser();
      const userAgent = parser.getResult();

      // Get referral from URL search params
      const searchParams = new URLSearchParams(location.search);
      const referralCode = searchParams.get('ref');

      // Get user's geolocation
      const geoResponse = await fetch('https://ipapi.co/json/');
      const geoData = await geoResponse.json();

      // Get current position from Firebase
      const currentPosition = await db.getWaitlistCount(
        DEFAULT_ACCOUNT_ID,
        id || ''
      );

      const waitlistEntry = {
        id: uuidv4(),
        referralId: generateReferralId(),
        position: currentPosition + 1,
        ...formData,
        referedCount: 0,
        ...(referralCode && { referredBy: referralCode }),
        joinedTime: new Date(),
        os: userAgent.os.name || 'Unknown',
        osVersion: userAgent.os.version || 'Unknown',
        browser: userAgent.browser.name || 'Unknown',
        browserVersion: userAgent.browser.version || 'Unknown',
        deviceType: userAgent.device.type || 'desktop',
        deviceModel: userAgent.device.model || 'Unknown',
        deviceVendor: userAgent.device.vendor || 'Unknown',
        ip: geoData.ip,
        region: geoData.region,
        city: geoData.city,
        state: geoData.region_code,
        country: geoData.country_name,
        zipcode: geoData.postal,
        lat: geoData.latitude,
        lng: geoData.longitude,
        emailSent: false,
      };

      await db.submitWaitlistEntry(DEFAULT_ACCOUNT_ID, id || '', waitlistEntry);
      navigate(`/success/${id}`, {
        state: { referralId: waitlistEntry.referralId },
      });
    } catch (error) {
      console.error('Error:', error);
      alert('Error submitting data. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Implement a function to generate a 6-character referral ID
  const generateReferralId = () => {
    return Math.random().toString(36).substring(2, 8);
  };

  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-6 text-center text-gray-800">
        Welcome to our website
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Name
          </label>
          <input
            {...register('name', {
              required: 'Full name is required',
              pattern: {
                value: /^[A-Za-z]+\s+[A-Za-z]+/,
                message: 'Please enter your full name (first and last name)',
              },
            })}
            id="name"
            type="text"
            className="mt-1 block w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400
            focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
            placeholder="Enter your name"
          />
          {errors.name && (
            <p className="mt-1 text-sm text-red-600">{errors.name.message}</p>
          )}
        </div>

        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email
          </label>
          <input
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Invalid email address',
              },
            })}
            id="email"
            type="email"
            className="mt-1 block w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400
            focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
            placeholder="Enter your email"
          />
          {errors.email && (
            <p className="mt-1 text-sm text-red-600">{errors.email.message}</p>
          )}
        </div>

        <div>
          <label
            htmlFor="mobileNumber"
            className="block text-sm font-medium text-gray-700"
          >
            Mobile Number (optional)
          </label>
          <input
            {...register('mobileNumber')}
            id="mobileNumber"
            type="tel"
            className="mt-1 block w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400
            focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
            placeholder="Enter your mobile number"
          />
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className={`w-full bg-gradient-to-r from-purple-500 to-pink-500 text-white py-2 px-4 rounded-lg hover:opacity-90 transition-opacity flex items-center justify-center gap-2
            ${
              isSubmitting
                ? 'opacity-75 cursor-not-allowed'
                : 'hover:opacity-90'
            }`}
        >
          {isSubmitting ? (
            <span className="flex items-center justify-center">
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Submitting...
            </span>
          ) : (
            'Submit'
          )}
        </button>
      </form>
      {/* <p className="mt-4 text-sm text-gray-500 text-center">
        Application ID: {id}
      </p> */}
    </Layout>
  );
};

export default UserData;
