import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Layout from '../components/layout';
import useWaitlist from '../hooks/useWaitlist';
import { db } from '../firebase-lib/firestore';
import { WaitlistEntry } from '../types/waitlist';
import { DEFAULT_ACCOUNT_ID } from '../config-global';

const Admin: React.FC = () => {
  const { id } = useParams();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [passcode, setPasscode] = useState('');
  const [error, setError] = useState('');
  const [correctPasscode, setCorrectPasscode] = useState('');

  const data = useWaitlist({
    id: DEFAULT_ACCOUNT_ID,
    applicationId: id || '',
  });

  const sortWaitlistEntries = (entries: WaitlistEntry[]): WaitlistEntry[] => {
    const sortedEntries: WaitlistEntry[] = [];
    for (const entry of entries) {
      const index = sortedEntries.findIndex(
        (item) => item.position > entry.position
      );
      if (index === -1) {
        sortedEntries.push(entry);
      } else {
        sortedEntries.splice(index, 0, entry);
      }
    }
    return sortedEntries;
  };

  const sortedData = data ? sortWaitlistEntries(data) : [];

  console.log(data);
  useEffect(() => {
    const fetchPasscode = async () => {
      const application = await db.application(DEFAULT_ACCOUNT_ID, id || '');

      if (application) setCorrectPasscode(application.passcode);
    };
    fetchPasscode();
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (passcode === correctPasscode) {
      setIsAuthenticated(true);
      setError('');
    } else {
      setError('Incorrect passcode');
    }
  };

  if (!isAuthenticated) {
    return (
      <Layout>
        <h1 className="text-2xl font-bold text-center mb-6">
          Admin Authentication
        </h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-2">
            <label
              htmlFor="passcode"
              className="block text-sm font-medium text-gray-700"
            >
              Enter Admin Passcode
            </label>
            <input
              id="passcode"
              type="password"
              value={passcode}
              onChange={(e) => setPasscode(e.target.value)}
              placeholder="Enter passcode"
              className="w-full py-2 px-4 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
            />
            {error && (
              <div className="text-red-500 text-sm mt-1">
                <span role="alert">{error}</span>
              </div>
            )}
          </div>
          <button
            type="submit"
            className="w-full bg-gradient-to-r from-purple-500 to-pink-500 text-white py-2 px-4 rounded-lg hover:opacity-90 transition-opacity flex items-center justify-center gap-2"
          >
            Access Admin Panel
          </button>
        </form>
      </Layout>
    );
  }

  return (
    <Layout width="max-w-8xl">
      <h1 className="text-2xl font-bold mb-6">Waitlist Management</h1>
      <div className="overflow-y-auto" style={{ maxHeight: '70vh' }}>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 sticky top-0 z-10">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Position
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Joined Time
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Email
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Location
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Referral Info
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Device Info
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {sortedData?.map((item) => (
              <tr key={item.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    #{item.position}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {new Date(
                      (item.joinedTime as any).seconds * 1000
                    ).toLocaleString()}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {item.name}
                  </div>
                  <div className="text-sm text-gray-500">
                    {item.mobileNumber}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{item.email}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {item.city}, {item.country}
                  </div>
                  <div className="text-sm text-gray-500">{item.zipcode}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    ID: {item.referralId}
                  </div>
                  <div className="text-sm text-gray-500">
                    Referred by: {item?.referredBy || 'N/A'}
                  </div>
                  <div className="text-sm text-gray-500">
                    Referral count: {item?.referedCount || 'N/A'}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {item.browser} {item.browserVersion}
                  </div>
                  <div className="text-sm text-gray-500">
                    {item.os} {item.osVersion}
                  </div>
                  <div className="text-sm text-gray-500">{item.deviceType}</div>
                  <div className="text-sm text-gray-500">
                    {item.deviceModel &&
                      `${item.deviceVendor}: ${item.deviceModel}`}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default Admin;
