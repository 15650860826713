import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import UserData from './pages/userData';
import Success from './pages/Success';
import Admin from './pages/Admin';
import { DEFAULT_APPLICATION_ID } from './config-global';

const RedirectComponent: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/waitlist/${DEFAULT_APPLICATION_ID}`);
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <div className="w-12 h-12 rounded-full border-4 border-purple-500 border-t-transparent animate-spin"></div>
      <p className="mt-4 text-gray-600 font-medium">Loading...</p>
    </div>
  );
};

const App: React.FC = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <Routes>
        <Route path="/" element={<RedirectComponent />} />
        <Route path="/waitlist/:id" element={<UserData />} />
        <Route path="/admin/:id" element={<Admin />} />
        <Route path="/success/:id" element={<Success />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
};

export default App;
